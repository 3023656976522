<template>
  <DbtForm Heading = 'DBT Beneficiary Migration' @submit="submit"/>
</template>

<script>
import DbtForm from './DbtForm.vue'
import { mapState, mapActions } from 'vuex'
import { DataForMatomoTracking, initializeNikshayMatomo, adminLogData } from '../../utils/matomoTracking'
import { apiResponseType } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
export default {
  components: { DbtForm },
  data: () => ({
  }),
  computed: {
    ...mapState([
      'isProd'
    ])
  },
  mounted: function () {
    initializeNikshayMatomo()
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'success'
    ]),
    async submit (hierarchyid, beneficiarytype, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })
      const data = null
      let jsonUploadResponse
      try {
        jsonUploadResponse = await ApiClient.get(
          username,
          password,
          data,
          '/api/Configuration/UpdateDBTBeneficiaryMigrationConfig?key=' + beneficiarytype
        )
      } catch (error) {
        this.loading(false)
        this.setErrorMessage({ isError: true, errMsg: apiResponseType.HIERARCHYUPDATEFAIL })
      }
      if (jsonUploadResponse.data.Success) {
        try {
          const res = await ApiClient.post(
            username,
            password,
            data,
            '/api/Configuration/EnqueueJob?jobName=DbtBeneficiaryMigration&hierarchyId=' + hierarchyid + '&doForLatestOnly=true'
          )
          this.loading(false)
          this.setErrorMessage({ isError: false, errMsg: '' })
          this.success({ isOk: true, succMsg: 'JOB Triggered Successfully' })
        } catch (error) {
          this.loading(false)
          this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
        }
      } else {
        this.loading(false)
        this.setErrorMessage({ isError: true, errMsg: apiResponseType.HIERARCHYUPDATEFAIL })
      }
    }
  }
}
</script>
